import React from 'react'
import { BackgroundContainer, ContentContainer, TextDiv, TitleDiv, DescriptionDiv, ButtonDiv, ImageDiv, MainImage } from './styles'
import HeaderBackground from '../../images/imagesHead/HeaderBackground.svg'

function BannerHead(props) {

   return(
      <BackgroundContainer>
         <ContentContainer>

            <TextDiv>
               <TitleDiv>
                  <span><b>{props.titleHead}</b></span>
               </TitleDiv>
               <DescriptionDiv>
                  <span>{props.descriptionHead}</span>
               </DescriptionDiv>
               <ButtonDiv >
                  <a href={props.urlBtn} target="_blank" rel="noopener noreferrer">{props.btnText}</a>
               </ButtonDiv>
            </TextDiv>

            <ImageDiv>
               <img src={HeaderBackground} alt="Background" style={{ position: 'absolute', bottom: 0, right: 0 }}/>
                  <MainImage center={props.center}>
                     <img src={props.image} alt={props.imageName}/>
                  </MainImage>
            </ImageDiv>

         </ContentContainer>
      </BackgroundContainer>
   )

}

export default BannerHead