import styled from 'styled-components'

export const BackgroundContainer = styled.div`

   width: 100%;
   background: linear-gradient(180deg, #00879A 0%, #00BFBF 100%);
   display: flex;
`

export const ContentContainer = styled.div`

   width: 100%; 
   display: flex; 
   height: 1080px;

   @media(min-width:1280px) and (max-width: 1440px){
      height: 950px;
   }

   @media(min-width:830px) and (max-width: 1024px){
      height: 880px;
   }
`

export const TextDiv = styled.div`

   display: flex;
   flex-direction: column;
   width: 40%; 
   align-items: flex-end;
   justify-content: center;

   @media(min-width: 320px) and (max-width: 768px){
      width: 100%;
      align-items: center;
   }


`

export const TitleDiv = styled.div`

   display: flex;
   justify-content: flex-end;

   & > span{

      color: #F2F3F8;
      font-size: 48px;
      text-transform: uppercase;
      text-align: right;
      max-width: 530px;
   }

   @media (min-width: 835px) and (max-width: 1024px){
   & > span{
      font-size: 60px;
   }
   }

   @media (min-width: 830px) and (max-width: 834px){
      & > span{
         font-size: 50px;
      }
   }

   @media (min-width: 320px) and (max-width: 414px){
      & > span{
         font-size: 55px;
         text-align: center;

      }
   }

`

export const DescriptionDiv = styled.div`

   display: flex;
   justify-content: flex-end;
   margin-top: 24px;

   & > span{

      color: #1E3760;
      font-weight: normal;
      font-size: 24px;
      text-align: right;
      max-width: 560px;

      @media(min-width: 1025px) and (max-width:1366px){
         max-width: 500px;
      }

      @media(min-width: 770px) and (max-width:1024px){
         margin-left: 20px;
      }

      @media(max-width:768px){
         text-align: center;
      }

      @media (min-width: 320px) and (max-width: 414px){
         width: 300px;
      }

   }
`

export const ButtonDiv = styled.div`

   display: flex;
   justify-content: flex-end;
   margin-top: 50px;
   border: 1px solid #FFFFFF;
   box-sizing: border-box;
   border-radius: 18px;

   & > a{
      text-transform: uppercase;
      color: #FFFFFF;
      padding: 10px 50px 10px 50px;
      font-size: 14px;
      text-decoration: none;
   }
   &:hover{
      cursor: pointer;
      border: 1px solid #1E3760;
      background: #22EEE1;
      & > a{
         color: #1E3760;
      }

   }
`

export const ImageDiv = styled.div`

   display: flex; 
   width: 60%;
   justify-content: center;
   position: relative;

   @media (min-width: 320px) and (max-width: 830px){
      display: none;
   }


`

export const MainImage = styled.div`

   display: flex; 
   justify-content: center; 
   align-items:${props => props.center? 'center' : 'flex-end'} ; 
   z-index: 1;

   @media (min-width: 1367px) and (max-width: 1440px){
      & > img{
         height: 630px;
      }
   }

   @media (min-width: 1025px) and (max-width: 1366px){
      & > img{
         height: 500px;
      }
   }
   @media (min-width: 835px) and (max-width: 1024px){
   
      & > img{
         height: 430px;
      }

   }

   @media (min-width: 831px) and (max-width: 834px){
      & > img{ 
         height: 390px;
      }
   }

   @media (min-width: 320px) and (max-width: 830px){
      display: none;
   }

`