import styled from 'styled-components'

export const BannerFooter = styled.div`
   height:500px;
   background: #08C1C1;
   display:flex;
   justify-content: center;
   flex-direction: row;
   align-items: center;

   @media (min-width: 320px) and (max-width: 414px){
      height: auto;   
      flex-direction: column;
   }


`

export const DivTextoFoot = styled.div`

   display: flex;
   flex-direction: column;
   max-width: 750px;
   margin-right: 100px;

   & > span{
      font-weight: bold;
      font-size: 48px;
      line-height: 57px;
      text-align: right;
      text-transform: uppercase;
      color: #1E3760;
   }

   @media(min-width: 768px) and (max-width: 1024px){

      margin-right: 40px;
      width: 47%;

      & > span{   
         font-size: 30px;
         line-height: 45px;
      }
   }
   
   @media (min-width:765px) and (max-width: 840px){
      margin-right: 30px;
      & > span{   
         font-size: 30px;
         line-height: 40px;
      }
   }

   @media (min-width: 320px) and (max-width: 414px){
      margin: 30px;
      & > span{   
         font-size: 30px;
         line-height: 40px;
      }
   }

`

export const DivLine = styled.div`

   height: 180px;
   width: 2px;
   background: #1E3760;

   @media (min-width: 320px) and (max-width: 414px){
      height: 2px;
      width: 180px;
   }

`

export const DivButtonFoot = styled.div`

   display: flex;
   flex-direction: column;
   max-width: 420px;
   margin-left: 100px;

   & > .texto-bfooter{
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #1E3760;
      margin-bottom: 70px;
      display: flex;
      flex-direction: column;

      @media (min-width: 765px) and (max-width: 840px){
         font-size: 20px;
      }
   }

   & > .button-bfooter{
      color: #1E3760;
      padding: 10px 50px;
      border: 1px solid #1E3760;
      border-radius: 18px;
      text-decoration: none;
      text-align: center;
      align-self: flex-start;

      &:hover{
         background: #22EEE1;
      }
   }

   @media (max-width: 1024px){
      margin-left: 40px;
   }
 
   @media (min-width:765) and (max-width: 840px){
      margin-left: 30px;
   }

   @media (min-width: 320px) and (max-width: 414px){
      margin: 30px   
   }

`