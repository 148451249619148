import React from 'react'
import { BannerFooter, DivTextoFoot, DivLine, DivButtonFoot } from './styles'

export default ({ children }) => {

   return(

      <BannerFooter>
         <DivTextoFoot>
            <span>
            {
               children
            }
            </span>
         </DivTextoFoot>

         <DivLine>
         </DivLine>

         <DivButtonFoot>
            <div className='texto-bfooter'>
               <span>Muito além dos serviços bancários.</span>
               <span>Você precisa experimentar.</span>
            </div>
            <a className='button-bfooter' href='https://www.wowmanager.com.br/signup' rel="noopener noreferrer" target='_blank'>CRIAR CONTA</a>      
         </DivButtonFoot>
      </BannerFooter>

   )

}